<template>
  <v-container fluid>
    <BaseBreadcrumb
      class="py-2"
      :title="page.title"
      :icon="'mdi-account-outline'"
    ></BaseBreadcrumb>

    <v-progress-linear
      v-if="isUserProfileLoading"
      indeterminate
    ></v-progress-linear>

    <v-card class="pa-5" v-if="userProfile && !isUserProfileLoading">
      <v-card-title class="text-h5">
        <v-row justify="center">
          <v-avatar color="primary" size="90">
            <span class="white--text text-h4">{{ avatar }}</span>
          </v-avatar>
        </v-row>
      </v-card-title>
      <v-card-subtitle class="mt-1">
        <v-row justify="center" align="center" class="mt-4">
          <h2>{{ userProfile.fullName }}</h2>
          <v-btn
            class="mb-1 ml-1"
            :loading="isEditLoading"
            @click="onEditDialogClick"
            icon
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-row>
        <v-row justify="center" class="mt-4">
          <h4>Email: {{ userProfile.email }}</h4>
        </v-row>
        <v-row justify="center" class="mt-5" v-if="userProfile.phoneNumber">
          <h4>Teléfono: {{ userProfile.phoneNumber }}</h4>
        </v-row>
      </v-card-subtitle>

      <v-card-text class="text-center" v-if="userProfile.teams.length > 1">
        <v-divider class="my-3"></v-divider>

        <h3 class="py-2">Mis Equipos</h3>

        <li v-for="team in userProfile.teams" :key="team.teamTokenId">
          {{ team.name }}
          <span v-if="team.teamTokenId === userProfile.defaultTeamTokenId">
            (por defecto)</span
          >
        </li>
      </v-card-text>
    </v-card>

    <v-dialog scrollable width="600" v-model="editDialog" persistent>
      <v-card class="pa-1">
        <v-card-title class="text-h5">Editar Perfil </v-card-title>
        <v-card-text>
          <v-text-field
            class="mt-2"
            v-model="editedUser.firstName"
            outlined
            dense
            label="Nombre"
          ></v-text-field>

          <v-text-field
            v-model="editedUser.lastName"
            outlined
            dense
            label="Apellidos"
          ></v-text-field>

          <vue-tel-input-vuetify
            :inputOptions="vueTelInputOptions"
            ref="vueTelInputRef"
            v-model="userPhone.number"
            :preferredCountries="preferredCountries"
            :ignoredCountries="ignoredCountries"
            :disabledFetchingCountry="true"
            :defaultCountry="'ES'"
            label="Teléfono"
            placeholder=""
            @input="onPhoneInput"
            outlined
            dense
          >
          </vue-tel-input-vuetify>

          <li
            class="red--text text--darken-1 text-caption"
            v-for="(error, index) in editProfileDialogErors"
            :key="index"
          >
            {{ error }}
          </li>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutter class="pa-2">
            <v-btn
              color="primary"
              text
              @click="onPassDialogClick"
              class="ma-2 btn--expanded"
              outlined
              >Cambiar contraseña</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2 btn--expanded"
              outlined
              color="red"
              text
              @click="editDialog = false"
              >Cancelar</v-btn
            >

            <v-btn
              color="primary"
              class="ma-2 btn--expanded"
              outlined
              :loading="isEditLoading"
              text
              @click="updateProfile"
              >Actualizar</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable width="600" v-model="passDialog" persistent>
      <v-card class="pa-1">
        <v-card-title class="text-h5">Cambiar contraseña </v-card-title>

        <v-card-text>
          <v-text-field
            label="Contraseña actual"
            :append-icon="
              showCurrentPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
            "
            :type="showCurrentPassword ? 'text' : 'password'"
            v-model="currentPassword"
            required
            outlined
            dense
            @click:append="showCurrentPassword = !showCurrentPassword"
          ></v-text-field>

          <v-text-field
            label="Contraseña nueva"
            :type="showNewPassword ? 'text' : 'password'"
            :append-icon="
              showNewPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
            "
            v-model="newPassword"
            required
            outlined
            dense
            @click:append="showNewPassword = !showNewPassword"
          ></v-text-field>

          <v-text-field
            label="Repetir contraseña nueva"
            :type="showNewRepeatedPassword ? 'text' : 'password'"
            :append-icon="
              showNewRepeatedPassword
                ? 'mdi-eye-outline'
                : 'mdi-eye-off-outline'
            "
            v-model="newRepeatedPassword"
            required
            outlined
            dense
            @click:append="showNewRepeatedPassword = !showNewRepeatedPassword"
          ></v-text-field>

          <li
            class="red--text text--darken-1 text-caption"
            v-for="(error, index) in editPasswordDialogErrors"
            :key="index"
          >
            {{ error }}
          </li>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="passDialog = false">Cancelar</v-btn>

          <v-btn
            color="primary"
            :loading="isPassLoading"
            text
            @click="updatePassword"
            >Cambiar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Constants from "../signatures/remote/ConstantsPSC";

export default {
  name: "Profile",

  data: () => ({
    page: {
      title: "Mi Perfil"
    },
    preferredCountries: Constants.PSC_RECIPIENT_OTP_PREFERRED_COUNTRIES,
    ignoredCountries: Constants.PSC_RECIPIENT_OTP_IGNORED_COUNTRIES,
    editDialog: false,
    passDialog: false,
    userPhone: {},
    isEditLoading: false,
    isPassLoading: false,
    editedUser: {},
    editProfileDialogErors: [],
    editPasswordDialogErrors: [],
    isUserProfileLoading: false,

    showNewPassword: "",
    showNewRepeatedPassword: "",
    showCurrentPassword: false,
    currentPassword: "",
    newPassword: "",
    newRepeatedPassword: "",
    vueTelInputOptions: { showDialgCode: true }
  }),

  created() {
    this.userPhone = JSON.parse(
      JSON.stringify(Constants.PSC_RECIPIENT_DEFAULT_PHONE)
    );
    this.getUserProfile();
  },

  methods: {
    getUserProfile() {
      this.isUserProfileLoading = true;
      this.$store
        .dispatch("user/getUserProfile")
        .then(() => {
          this.isUserProfileLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.toast_error({
            message: "No se ha podido recuperar el perfil de usuario"
          });

          this.isUserProfileLoading = false;
        });
    },

    onPassDialogClick() {
      this.editDialog = false;
      this.passDialog = true;
    },

    onEditDialogClick() {
      this.editDialog = true;
      this.userPhone.number = JSON.parse(
        JSON.stringify(this.userProfile.phoneNumber)
      );
      this.editedUser = JSON.parse(JSON.stringify(this.userProfile));
    },

    checkProfile() {
      this.editProfileDialogErors = [];
      if (!this.editedUser.firstName) {
        this.editProfileDialogErors.push("Introduce un nombre válido.");
      }

      if (!this.editedUser.lastName) {
        this.editProfileDialogErors.push("Introduce un apellido válido.");
      }

      if (!this.userPhone.valid) {
        this.editProfileDialogErors.push("Introduce un teléfono válido.");
      }

      if (this.editProfileDialogErors.length === 0) {
        this.editedUser.phoneNumber = this.userPhone.number.replace(/\s/g, "");
        return true;
      }
      return false;
    },

    updateProfile() {
      if (this.checkProfile()) {
        this.isEditLoading = true;
        this.$store
          .dispatch("user/setUserProfile", this.editedUser)
          .then(response => {
            if (response.status === 200) {
              this.processSuccessEditResponse();
            } else {
              this.processErrorEditResponse();
            }
          })
          .catch(error => {
            console.log(error);
            this.processErrorEditResponse();
          });
      }
    },

    processSuccessEditResponse() {
      this.userProfile.fullName =
        this.userProfile.firstName + " " + this.userProfile.lastName;
      this.isEditLoading = false;
      this.editDialog = false;
      this.$toasted.global.toast_success({
        message: "Los cambios se han guardado correctamente"
      });
    },

    processErrorEditResponse() {
      this.isEditLoading = false;
      this.editDialog = false;
      this.$toasted.global.toast_error({
        message: "Los cambios no se han podido guardar correctamente"
      });
    },

    onPhoneInput(_, { number, valid, country }) {
      this.userPhone.number = number.international;
      this.userPhone.valid = valid;
      this.userPhone.country = country && country.name;
    },

    checkPasswords() {
      this.editPasswordDialogErrors = [];
      if (this.newPassword != this.newRepeatedPassword) {
        this.editPasswordDialogErrors.push(
          "Las nuevas contraseñas no coinciden"
        );
      }

      if (this.editPasswordDialogErrors.length === 0) {
        return true;
      }
      return false;
    },

    updatePassword() {
      if (this.checkPasswords()) {
        this.isPassLoading = true;
        this.$store
          .dispatch("updatePassword", {
            currentPassword: this.currentPassword,
            newPassword: this.newPassword
          })
          .then(() => {
            this.$toasted.global.toast_success({
              message: "La contraseña se ha actualizado correctamente"
            });
            this.isPassLoading = false;
            this.passDialog = false;
            this.newPassword = "";
            this.currentPassword = "";
            this.newRepeatedPassword = "";
          })
          .catch(err => {
            var message = "No se ha podido actualizar la contraseña <br>";
            if (err.response.data != null && err.response.data.length > 0) {
              for (var i = 0; i < err.response.data.length; i++) {
                message += err.response.data[i].description + "<br>";
              }
            }
            this.$toasted.global.toast_error({
              message: message
            });
            this.isPassLoading = false;
          });
      }
    }
  },

  computed: {
    userProfile() {
      return this.$store.getters["user/userProfile"];
    },
    avatar() {
      var avatar = "";
      try {
        avatar += this.userProfile.firstName[0] ?? "";
        avatar += this.userProfile.lastName[0] ?? "";
      } catch (e) {
        console.log(e);
      }
      return avatar;
    }
  }
};
</script>

<style scoped>
@media (max-width: 581px) {
  .btn--expanded {
    width: 95%;
  }
}
</style>
